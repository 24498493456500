<template>
  <aside class="rewards-aside-image" :class="item.container_class">
    <div class="md:pl-8 lg:pl-18">
      <div class="rewards-image relative py-7 mb-4 lg:mb-13">
        <span class="block relative overflow-hidden rounded-full z-1 mx-auto">
          <img :src="item.image" :alt="item.title">
        </span>
      </div>
      <h4 class="uppercase text-md tracking-mid leading-none font-bold text-center sm:text-left">
        {{ item.title }}
        <span class="text-above-md block font-serif italic font-normal tracking-normal mt-xs normal-case">
          {{ item.subtitle }}
        </span>
      </h4>
    </div>
  </aside>
</template>

<script>
import { Blok } from 'src/modules/vsf-storyblok-module/components'

export default {
  name: 'RewardsAsideImage',
  extends: Blok
}
</script>

<style lang="scss" scoped>
.rewards-image {
  @apply w-full;

  @screen md {
    width: 270px;
  }

  span {
    width: 180px;
    height: 180px;
    &:after {
      @apply absolute rounded-full z-1 top-0 left-0;
      box-shadow: inset 0 4px 10px 0 rgba(0,0,0,.25);
      content: '';
      height: 180px;
      width: 180px;
    }
  }
  &:after {
    @apply absolute top-0 z-0 bg-no-repeat bg-left-top;
    background-image: url('/assets/pattern-tile.png');
    content: '';
    height: 230px;
    width: 210px;
    right: 50%;
  }
}
</style>
